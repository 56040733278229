.navbar {
  background-color: #fcfcfc;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 32px;
  justify-content: space-between;
}

.setcoLogo {
  height: 32px;
  display: block;
  margin-bottom: 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.setcoLogo::after {
  content: "";
  position: absolute;
  top: 0;
  left: -150%;
  height: 100%;
  width: 150%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.8),
    transparent
  );
  transform: skewX(-25deg);
  transition: all 0.1s ease-in-out;
}

.setcoLogo:hover::after {
  left: 100%;
}

.setcoLogo:hover {
  transform: scale(1.051);
}

.logoContainer {
  width: 270px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionMenu {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: "space-between";
  margin-right: 24px;
  width: 100%;
  padding-left: 16px;
}

.quickLinksNav {
  flex: 2;
  display: none;
}

.actionItem {
  text-decoration: none;
  color: #384b70;
}

@media only screen and (min-width: 1025px) {
  .quickLinksNav {
    display: flex;
    align-items: center;
    gap: 24px;
  }
}
