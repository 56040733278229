.sideBar {
  height: calc(100dvh - 64px);
  width: 270px;
  border-right: 1px solid lightgray;
  position: absolute;
  left: 0;
  z-index: 2000;
  background-color: #fcfcfc;

  /* padding: 24px; */
}

.optionsList {
  height: calc(100% - 100px);
  overflow-y: auto;
  /* background-color: blue; */
}

.optionsList {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.optionsList::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.sidebarFooter {
  position: absolute;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid lightgray;
  bottom: 0;
  left: 0;
  width: 270px;
  background-color: #fcfcfc;
  border-right: 1px solid lightgray;
}

.footerLogo {
  height: 32px;
}

@media only screen and (min-width: 1025px) {
}
