.settingsNav {
  width: 100%;
  display: flex;
  gap: 24px;
  padding: 24px;
  cursor: pointer;
  margin-bottom: 16px;
}

.settingOption {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.activeSetting {
  color: #384b70;
  background-color: #fff;
  border-bottom: 2px solid #384b70;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.userSettingsNav {
  display: flex;
  gap: 24px;
  margin-left: 36px;
}
