.page {
  height: 100dvh;
}

.navContainer {
  height: 64px;
  border-bottom: 1px solid lightgray;
  /* background-color: rgba(214, 161, 161, 0.2); */
}

.blurBelow {
  position: fixed; /* Ensures overlay stays in place during scroll */
  left: 0;
  top: 64px; /* Adjusts top position */
  width: 100vw; /* Covers the entire viewport width */
  height: 100vh; /* Covers the entire viewport height */
  background-color: rgba(90, 90, 90, 0.8);
  z-index: 100;
  /* backdrop-filter: blur(1px);  */
  /* Applies a blur effect to the background */
  /* -webkit-backdrop-filter: blur(1px);  */
  /* Safari support */
}

@media only screen and (min-width: 1025px) {
  .pageContainer {
    display: flex;
  }
}
